<template>
    <a :href="setLinkUrl(content.link.url)"
            class="btn-example"
            type="button"
            :class="[
            { 'has-icon': buttonHasIcon && content.text },
            {
                [`has-animation-${[content.animation.type]}`]: content.animation.enable && content.animation.type
            }
          ]"
            :style="{
            maxWidth: content.style.minWidth + '%',
            minHeight: content.style.minHeight + 'px',
            borderRadius: content.style.borderRadius + 'px',
            borderWidth: content.style.borderWidth + 'px',
            borderColor: content.style.borderColor,
            borderStyle: content.style.borderStyle,
            background: buttonBg,
            color: content.style.color
        }"
    >
        <div class="btn-example__inner">
            <div class="btn-example__icon" v-if="buttonHasIcon">
                <svg v-if="content.icon.type === 'icon'" :style="{color: content.icon.color}">
                    <use v-bind="{ 'xlink:href': `${symbolDefs}#tabler-${content.icon.svg}` }"></use>
                    <!--        <use :xlink:href="`${symbolDefs}#icon-${src}`"></use>-->
                </svg>
                <img v-if="content.icon.type === 'image'" :src="content.icon.image.url" alt=""/>
                <!--                       <span class="fas fa-address-card"></span>-->
            </div>
            <div class="btn-example__text" v-html="content.text" v-if="content.text"></div>
        </div>
    </a>
</template>

<script>
    import '../assets/styles/animation.scss'
    import {setLinkUrl} from "../config/helpers";

    export default {
        name: "BlockButton",
        props: {
            content: {}
        },
        computed: {
            symbolDefs() {
                return require("@/assets/icons/icons-sprite.svg");
            },
            buttonHasIcon() {
                if (this.content.icon.type === "icon") {
                    return !!this.content.icon.svg;
                }
                return !!this.content.icon.image;
            },
            buttonBg() {
                const bg = this.content.style.bg;
                if (bg.type === "color") {
                    return bg.color1;
                }
                return `linear-gradient(to right, ${bg.color1}, ${bg.color2})`;
            }
        },
        methods: {
            setLinkUrl
        }
    };
</script>

<style scoped lang="scss">
    .btn-example {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        width: 100%;
        &__inner {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__icon {
            position: relative;
            width: 42px;
            height: 42px;
            border-radius: 3px;
            overflow: hidden;

            svg {
                height: 100%;
                width: 100%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__text {
            flex-grow: 1;
            flex-shrink: 0;
            text-align: center;
        }

        &.has-icon {
            padding-right: 42px;

            .btn-example__inner {
                width: 100%;
            }
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5 {
            margin: 0;
        }
    }
</style>
