var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"btn-example",class:[
        { 'has-icon': _vm.buttonHasIcon && _vm.content.text },
        ( _obj = {}, _obj[("has-animation-" + ([_vm.content.animation.type]))] = _vm.content.animation.enable && _vm.content.animation.type, _obj )
      ],style:({
        maxWidth: _vm.content.style.minWidth + '%',
        minHeight: _vm.content.style.minHeight + 'px',
        borderRadius: _vm.content.style.borderRadius + 'px',
        borderWidth: _vm.content.style.borderWidth + 'px',
        borderColor: _vm.content.style.borderColor,
        borderStyle: _vm.content.style.borderStyle,
        background: _vm.buttonBg,
        color: _vm.content.style.color
    }),attrs:{"href":_vm.setLinkUrl(_vm.content.link.url),"type":"button"}},[_c('div',{staticClass:"btn-example__inner"},[(_vm.buttonHasIcon)?_c('div',{staticClass:"btn-example__icon"},[(_vm.content.icon.type === 'icon')?_c('svg',{style:({color: _vm.content.icon.color})},[_c('use',_vm._b({},'use',{ 'xlink:href': (_vm.symbolDefs + "#tabler-" + (_vm.content.icon.svg)) },false))]):_vm._e(),(_vm.content.icon.type === 'image')?_c('img',{attrs:{"src":_vm.content.icon.image.url,"alt":""}}):_vm._e()]):_vm._e(),(_vm.content.text)?_c('div',{staticClass:"btn-example__text",domProps:{"innerHTML":_vm._s(_vm.content.text)}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }